// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBkZpnVaPYLK5SEA9Ym-i2JbCYO80aXL84",
  authDomain: "idpost-55a4f.firebaseapp.com",
  databaseURL: "https://idpost-55a4f-default-rtdb.firebaseio.com",
  projectId: "idpost-55a4f",
  storageBucket: "idpost-55a4f.appspot.com",
  messagingSenderId: "392851864905",
  appId: "1:392851864905:web:2b021ed776155d5256ee98",
  measurementId: "G-EM4LLD6L87"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };